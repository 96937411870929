import { LoaderFunctionArgs } from "@remix-run/server-runtime";
import { json } from '@remix-run/node';
import { MetaFunction, Outlet, useLoaderData } from "@remix-run/react";
import { Anchor, AppShell, Container, Group } from '@mantine/core';
import { getNotification } from "../services/notification.server";
import { NotificationData } from "@mantine/notifications";
import { useEffect } from "react";
import { showNotification } from "../utils/notification-utils";
import { encodeSuper, useSuperLoaderData } from "remix-superloader";



export const loader = async ({ request }: LoaderFunctionArgs) => {

  const { notification, headers } = await getNotification(request);

  if (notification && headers) {
    return encodeSuper(
      { notification },
      { headers }
    )
  }
  else {
    return {
      notification: null
    };
  }
};

export const meta: MetaFunction = () => {
  return [
    { title: "DigitalIQ Portal" },
    { name: "description", content: "Welcome to the DigitalIQ portal!" },
  ];
};

export default function IndexLayout() {

  const loaderData = useSuperLoaderData();
  const notification = loaderData.notification as NotificationData | null;

  useEffect(() => {
    if (notification) {
      showNotification(notification);
    }
  }, [notification])

  return (
    <AppShell padding="md">
      <AppShell.Header p="sm">
        <Group justify="right">
          <Anchor href="/auth/login">
            Login
          </Anchor>
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        <Container my={40}>

        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
